<!--
 * @Author: lzh
 * @Date: 2022-07-06 17:08:25
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-07 10:45:03
 * @Description: file content
-->
<template>
  <div class="edit-offer-data">
    <div class="cell-b">
      <div class="cell-b">
        <h4>主標題</h4>
        <selfCell
          v-for="item in language"
          :key="item.code"
          :title="item.name"
        >
          <el-input
            v-model="configs[`${item.code == 'HK' ? '' : item.code}title`]"
            :placeholder="`請輸入標題-${item.name}`"
            @input="setValue(`${item.code == 'HK' ? '' : item.code}title`)"
            type="textarea"
            rows="3"
          ></el-input>
        </selfCell>
      </div>
      <div class="cell-b">
        <h4>副標題</h4>
        <selfCell
          v-for="item in language"
          :key="item.code"
          :title="item.name"
        >
          <h3></h3>
          <el-input
            v-model="configs[`${item.code == 'HK' ? '' : item.code}desc`]"
            :placeholder="`請輸入標題-${item.name}`"
            @input="setValue(`${item.code == 'HK' ? '' : item.code}desc`)"
            type="textarea"
            rows="3"
          ></el-input>
        </selfCell>
      </div>
    </div>
    <div class="cell-b">
      <h3>項-主標題
        <el-tooltip
          class="item"
          effect="dark"
          content="新增一項"
          placement="bottom"
        >
          <el-button
            icon="el-icon-plus"
            circle
            size="mini"
            style="margin-left:10px;"
            @click="addDom('dataArr',0)"
          ></el-button>
        </el-tooltip>
      </h3>
      <div
        v-for="(item, ind) in configs.childs[index].dataArr"
        :key="ind"
        class="cell-b title-box"
      >
        <div class="left">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code =='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入標題-${lItem.name}`"
            @input="setValue('childs')"
            type="textarea"
            rows="3"
          ></el-input>
        </div>
        <div class="right">
          <el-tooltip
            class="item"
            effect="dark"
            content="向上添加"
            placement="bottom"
          >
            <el-button
              icon="el-icon-top"
              circle
              size="mini"
              style="margin-left:10px;"
              @click="addDom('dataArr',ind)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="向下添加"
            placement="bottom"
          >
            <el-button
              icon="el-icon-bottom"
              circle
              size="mini"
              @click="addDom('dataArr',ind + 1)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="刪除"
            placement="bottom"
          >
            <el-button
              icon="el-icon-delete"
              circle
              size="mini"
              @click="delDom('dataArr',ind)"
            ></el-button>
          </el-tooltip>
        </div>
      </div>

    </div>

    <div>
      <div class="cell-b">
        <h3>項-價格配置</h3>
        <div
          v-for="item in language"
          :key="item.code"
          class="title-box"
        >
          <el-input
            v-model="configs.childs[index].price"
            placeholder="請輸入標題"
            @input="setValue('childs')"
          ></el-input>
          <el-input
            v-model="configs.childs[index][`${item.code == 'HK' ? '' : item.code}year`]"
            :placeholder="`請輸入年份-${item.name}`"
            @input="setValue('childs')"
          ></el-input>
        </div>
      </div>
      <div>
        <div
          v-for="(item, ind) in configs.childs[index].dataArr"
          :key="`${ind}a`"
          class="cell-b"
        >
          <div>
            <h4>項-標題配置
              <el-button
                icon="el-icon-plus"
                circle
                size="mini"
                @click="addDom('datas',ind , 0)"
              ></el-button>
            </h4>
            <div class="cell-b">
              <selfCell
                v-for="Litem in language"
                :key="Litem.code"
                :title="`主項名-${Litem.name}：`"
              >
                <el-input
                  v-model="item[`${Litem.code == 'HK' ? '' : Litem.code}subheading`]"
                  :placeholder="`請輸入主項名-${Litem.name}`"
                  @input="setValue('childs')"
                  type="textarea"
                  rows="3"
                ></el-input>
              </selfCell>
            </div>
          </div>
          <div
            v-for="(it_, in_) in item.datas"
            :key="`${in_}b`"
            class="title-box cell-b"
          >
            <div class="left">
              <el-input
                v-for="litem in language"
                :key="litem.code"
                v-model="it_[`${litem.code=='HK'?'':litem.code}title`]"
                :placeholder="`請輸入子項-${litem.name}`"
                @input="setValue('childs')"
                type="textarea"
                rows="3"
              ></el-input>
            </div>
            <div class="right">
              <el-tooltip
                class="item"
                effect="dark"
                content="向上添加"
                placement="bottom"
              >
                <el-button
                  icon="el-icon-top"
                  circle
                  size="mini"
                  style="margin-left:10px;"
                  @click="addDom('datas',ind , in_)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="向下添加"
                placement="bottom"
              >
                <el-button
                  icon="el-icon-bottom"
                  circle
                  size="mini"
                  @click="addDom('datas',ind , in_ + 1)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="刪除"
                placement="bottom"
              >
                <el-button
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="delDom('datas',ind , in_)"
                ></el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="cell-b">
        <h3>項-標題配置
          <el-tooltip
            v-if="configs.childs[index].checkData.length == 0"
            class="item"
            effect="dark"
            content="新增"
            placement="bottom"
          >
            <el-button
              icon="el-icon-plus"
              circle
              size="mini"
              @click="addDom('checkData',0)"
            ></el-button>
          </el-tooltip>
        </h3>
        <div
          v-for="(item, ind) in configs.childs[index].checkData"
          :key="`${ind}b`"
          class="title-box cell-b"
        >
          <div class="left">
            <el-input
              v-for="litem in language"
              :key="litem.code"
              v-model="item[`${litem.code=='HK'?'':litem.code}title`]"
              :placeholder="`請輸入底部項-${litem.name}`"
              @input="setValue('childs')"
              type="textarea"
              rows="3"
            ></el-input>
          </div>
          <div class="right">
            <el-tooltip
              class="item"
              effect="dark"
              content="向上添加"
              placement="bottom"
            >
              <el-button
                icon="el-icon-top"
                circle
                size="mini"
                style="margin-left:10px;"
                @click="addDom('checkData',ind)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="向下添加"
              placement="bottom"
            >
              <el-button
                icon="el-icon-bottom"
                circle
                size="mini"
                @click="addDom('checkData',ind+1)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="刪除"
              placement="bottom"
            >
              <el-button
                icon="el-icon-delete"
                circle
                size="mini"
                @click="delDom('checkData',ind)"
              ></el-button>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="cell-b">
        <h3>按鈕1</h3>
        <div class="title-box">
          <div
            class="left"
            style="display: flex;flex-direction: column;"
          >
            <el-input
              v-for="litem in language"
              :key="litem.code"
              :placeholder="`按鈕文字-${litem.name}`"
              v-model="configs.childs[index].btn1[`${litem.code=='HK'?'':litem.code}title`]"
              @input="setValue('childs')"
              type="textarea"
              rows="3"
            />
          </div>
          <selfPagePicker
            v-model="configs.childs[index].btn1.go"
            @onChange="setValue('childs')"
          />
        </div>

      </div>
      <div class="cell-b">
        <h3>按鈕2</h3>
        <div class="title-box">
          <div
            class="left"
            style="display: flex;flex-direction: column;"
          >
            <el-input
              v-for="litem in language"
              :key="litem.code"
              :placeholder="`按鈕文字-${litem.name}`"
              v-model="configs.childs[index].btn2[`${litem.code=='HK'?'':litem.code}title`]"
              @input="setValue('childs')"
              type="textarea"
              rows="3"
            />
          </div>
          <selfPagePicker
            v-model="configs.childs[index].btn2.go"
            @onChange="setValue('childs')"
          />
        </div>

      </div>

      <div class="cell-b">
        <h3>底部文字</h3>
        <el-input
          v-for="litem in language"
          :key="litem.code"
          :placeholder="`請輸入底部描述文字-${litem.name}`"
          v-model="configs[`${litem.code=='HK'?'':litem.code}attention`]"
          @input="setValue(`${litem.code=='HK'?'':litem.code}attention`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'edit-offer-data',
  props: {
    configs: {
      default () {
        return {
          childs: [{
            price: 0,
            year: "",
            dataArr: [],
            checkData: [],
            btn1: {
              title: "",
              go: ""
            },
            btn2: {
              title: "",
              go: ""
            }
          }]
        }
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  data () {
    return {
      language: []
    }
  },
  created () {
    this.language = this.$storage.get('language')
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    addDom (key, ind, t) {
      if (key == 'dataArr') {
        this.configs.childs[this.index].dataArr.splice(ind, 0, { title: "", datas: [], subheading: "" })
      } else if (key == 'datas') {
        this.configs.childs[this.index].dataArr[ind].datas.splice(t, 0, { title: "" })
      } else if (key == 'checkData') {
        this.configs.childs[this.index].checkData.splice(ind, 0, { title: "" })

      }
      this.setValue('childs')
    },
    delDom (key, ind, t) {
      if (key == 'dataArr') {
        this.configs.childs[this.index].dataArr.splice(ind, 1)
      } else if (key == 'datas') {
        this.configs.childs[this.index].dataArr[ind].datas.splice(t, 1)
      } else if (key == 'checkData') {
        this.configs.childs[this.index].checkData.splice(ind, 1)
      }
      this.setValue('childs')
    },
    addTitle1 () {
      // this.configs.childs[this.index].dataArr.push()
    },
    addTitle2 () { },
    addTitle3 () { },
  },
};
</script>

<style lang="less" scoped>
.edit-offer-data {
  .cell-b {
    box-shadow: 0 2px 6px #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .title-box {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}
</style>
